import React from "react"
import { graphql } from "gatsby"

import { GatsbyLocation } from "local-types"
import SEO from "../components/Seo/Seo"
import styled from "styled-components"
import { Row, Col } from "antd"


import Layout from "../components/Layout"
import SectionLayout from "../components/WrapperSections/SectionLayout"
import BlogRowCards from "../components/BlogComponents/BlogRowCards"
import Pagination from "../components/Pagination/Pagination"
import CategoryList from "../components/BlogComponents/CategoryList"

import { useSiteMetadata, usePageTransitions } from "../hooks"

const blogMetadata = {
  Title: "Flipbase Blog - Customer Stories, Trends and more",
  Keywords: ["Flipbase blog", "Flipbase customer stories"],
  Description: "Want to know more about Recruitment and Flipbase? Read our customer stories and recruitment-related blogs."
}

interface BlogPageProps {
  location: GatsbyLocation
  preivousPath?: string
  nextPath?: string
  data: object
  pageContext: object
}

const BlogPage: React.FunctionComponent<BlogPageProps> = ({
  location,
  preivousPath = "/Careers",
  nextPath = "/Contact",
  data,
  pageContext,
}): React.ReactElement => {
  const { title: siteTitle, author } = useSiteMetadata()
  const isBrowser = typeof window !== `undefined`
  let trasnlatePage

  const posts = data.allMdx
  if (location.state !== null && isBrowser) {
    if (location.state.prevPath != "/Careers") {
      trasnlatePage = -100
    } else {
      trasnlatePage = 100
    }
  }
  const { currentPage, pressNumPages, categoryList, slugPrefix } = pageContext

  const transitions = usePageTransitions({
    config: { duration: 700 },
    translateX: trasnlatePage,
    location,
  })
  
  return (
    <Layout
      location={location}
      title={siteTitle}
      preivousPath={preivousPath}
      nextPath={nextPath}
    >
      <SEO 
        title={blogMetadata.Title}
        description={blogMetadata.Description}
        keywords={blogMetadata.Keywords}
      />
      <SectionLayout>
        <Row align="middle">
          <StyledCol xl={16} lg={16} md={24} sm={24} xs={24}>
            <CategoryList 
              categoryList={categoryList} 
              listingType="/blog" 
              location={location}
            />
          </StyledCol>
        </Row>

        <BlogRowCards data={posts} />

        <Pagination
          currentPage={currentPage}
          numPages={pressNumPages}
          marginBottom="64px"
          slugPrefix={slugPrefix}
        />
      </SectionLayout>
    </Layout>
  )
}

const StyledCol = styled(Col)`
  margin: 0 auto;
`

export default BlogPage

export const pageQuery = graphql`
  query blogCategory($skip: Int!, $limit: Int!, $category: String!) {
    allMdx(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: {order: DESC, fields: frontmatter___created}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 80)
          frontmatter {
            title
            description
            date
            category
            featuredImage {
              relativePath
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
